import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class ContainerComponent implements OnInit {
  showSpinner=false;
  public show = false;
  constructor(private router:Router) { }

  ngOnInit() {
  }
  logout()
  {
    localStorage.clear();
    this.router.navigate(['/login']);

  }

loadData()
  {
    this.showSpinner=true;
    setTimeout(()=>
    {this.showSpinner=false;
    },500)
  }
  showSubmenu()
  {
   
    if (this.show)
      this.show = false;
    else
      this.show = true;

  }
}
