export class ConsigneeModel {
    id:                 number=0;
    consigneeName: string;
    consigneeDescription: string;
    consigneeShortAddress:string;
    consigneeFullAddress:string;
    consigneeGST:string;
    consigneeEmail:string;
    consigneePhone:string;

}
