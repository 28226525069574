import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDatepickerInputEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddconsigerComponent } from 'src/app/main-portal/masters/manage-consigner/addconsiger/addconsiger.component';
import { DocketService } from '../../docket.service';

@Component({
  selector: 'app-update-docket-status',
  templateUrl: './update-docket-status.component.html',
  styleUrls: ['./update-docket-status.component.css']
})
export class UpdateDocketStatusComponent implements OnInit {
docketStatus:any=[];
activityDate:string=new Date().toString();
activityStatus:string;
servingLocation:string;
statusId:number;
PODFile:any;
fileExt:string;
  constructor(public dialogRef:MatDialogRef<AddconsigerComponent>,private docketService:DocketService,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.getDocketStatus();
  }
  onDateChange(event:any)
  {
    this.activityDate=event.value;
  }
  uploadFileEvt(event: any) {
    const file = event.target.files[0];
    this.fileExt = file.name.substring(file.name.lastIndexOf('.') + 1,file.name.length);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        console.log(reader.result);
        this.PODFile=reader.result
    };
  }

  getDocketStatus() {
    this.docketService.getDocketStatus()
      .subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.docketStatus = response.data || [];
        } else {
          this.docketStatus = [];
        }
      });
  }
  onSubmit()
  {
    debugger
    let req={
      docketIds:this.data.docketIds.join(","),
      activityStatus:this.activityStatus,
      activityDate:this.activityDate,
      docketStatus:this.statusId,
       servingLocation:this.servingLocation,
       PODFile:this.PODFile!=undefined?this.PODFile.replace(/^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,/, ''):"",
       fileExt:this.fileExt
    }
    this.docketService.updateDocketStatus(req)
      .subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.docketStatus = response.data || [];
          
        } else {
          this.docketStatus = [];
        }
      });
      this.dialogRef.close();
  }
}
