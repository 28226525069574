import { Component, OnInit } from '@angular/core';
import { ActivityService } from './activity.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  activitiesList:Array<any>;

  constructor(private activityService:ActivityService, private router: Router) { 
    this.activitiesList=[];

  }

  ngOnInit() {
    this.getList();
  }
  getList(){
    this.activityService.getActivitiesList().subscribe
    ((res : any)=>{
      this.activitiesList=res;
    })
  }
  onClickDetails(id: string) {
    this.router.navigate(['/activity-details'], { queryParams: {id: id }});
  }
}
