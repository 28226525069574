import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

export class User{
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
   }

   public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  setAuth(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem('access_token', JSON.stringify(user.token));
    this.currentUserSubject.next(user)
  }
  removeAuth(){
    localStorage.clear();
    this.currentUserSubject.next(null);
    location.reload();
  }

    post(url, data): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/${url}`, data);
  }

  downloadFileBlob(url, data): Observable<Blob> {
    return this.http.post(`${environment.api_url}/${url}`, data,{responseType:'blob'});
  }

  put(url, data): Observable<any> {
    return this.http.put<any>(`${environment.api_url}/${url}`, data);
  }

  getById(url, data, isLoading: boolean = true): Observable<any> {
    return this.http.get<any>(`${environment.api_url}/${url}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getAll(url, data, isLoading: boolean = true): Observable<any> {

    return this.http.get<any>(`${environment.api_url}/${url}`)
      .pipe(map(res => {

        return res;
      }));
  }

  delete(url, data): Observable<any> {
    return this.http.delete<any>(`${environment.api_url}/${url}`);
  }
  patch(url, data, isLoading: boolean = true): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/${url}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

}

