import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddDocketComponent } from "src/app/main-portal/docket/add-docket/add-docket.component";
import { DocketListingComponent } from "src/app/main-portal/docket/docket-listing/docket-listing.component";
import { BillListingComponent } from './bill-listing/bill-listing.component';

const routes: Routes = [
    {
    path: 'docket-listing',
    component: DocketListingComponent
   },
     {
     path : 'add-docket',
     component: AddDocketComponent
   },

   {
    path : 'bill-listing',
    component: BillListingComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocketRoutingModule { }
