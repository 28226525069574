import { RouterModule, Routes } from "@angular/router";
import { ManageConsignerComponent } from "./manage-consigner/manage-consigner.component";
import { NgModule } from '@angular/core';
import { AddconsigerComponent } from "./manage-consigner/addconsiger/addconsiger.component";
import { VoicecallComponent } from "./manage-consigner/voicecall/voicecall.component";

const routes: Routes = [
    { path: 'consigner', component: ManageConsignerComponent },
    
    {path: 'addconsigner', component: AddconsigerComponent  },
    {path: 'voicecall', component: VoicecallComponent  },
    
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class MastersRoutingModule { }
