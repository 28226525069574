import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ManageConsignerComponent } from './manage-consigner/manage-consigner.component';
import { MastersRoutingModule } from './masters.routing.module';
import { AddconsigerComponent } from './manage-consigner/addconsiger/addconsiger.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogModule} from '@angular/material';
import { MatFormFieldModule,  MatCommonModule, MatTableModule,MatIconModule,MatButtonModule ,MatInputModule, MatSelectModule, MatNativeDateModule, MatDatepickerModule, MatPaginatorModule,MatRadioModule} from "@angular/material";
import { NewComponentComponent } from './manage-consigner/new-component/new-component.component';
import { CallComponent } from './manage-consigner/call/call.component';
import { VoicecallComponent } from './manage-consigner/voicecall/voicecall.component';
@NgModule({
  imports: [
    CommonModule,
    MastersRoutingModule,
    MatFormFieldModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule, MatPaginatorModule,MatRadioModule,
    ReactiveFormsModule,
    MatDialogModule,
    FormsModule,
    HttpClientModule,
    MatCommonModule
  ],
  declarations: [ManageConsignerComponent,AddconsigerComponent, NewComponentComponent, CallComponent, VoicecallComponent],entryComponents:[AddconsigerComponent]
})
export class MastersModule { }
