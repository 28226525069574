import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocketRoutingModule } from './docket-routing.module';
import { DocketListingComponent } from './docket-listing/docket-listing.component';
import { AddDocketComponent } from "src/app/main-portal/docket/add-docket/add-docket.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule, MatTableModule,MatIconModule,MatButtonModule ,MatInputModule, MatSelectModule, MatNativeDateModule, MatDatepickerModule, MatPaginatorModule,MatRadioModule} from "@angular/material";
import { BillListingComponent } from './bill-listing/bill-listing.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UpdateDocketStatusComponent } from './docket-listing/update-docket-status/update-docket-status.component';
import { AddConsigneeComponent } from "./add-docket/add-consignee/add-consignee.component";
import { AddConsignerComponent } from './add-docket/add-consigner/add-consigner.component';

// import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
@NgModule({
  imports: [
    CommonModule,
    DocketRoutingModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatRadioModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    
    
  //   NgxLoadingModule.forRoot({
  //     animationType: ngxLoadingAnimationTypes.wanderingCubes,
  //     backdropBackgroundColour: 'rgba(0,0,0,0.1)', 
  //     backdropBorderRadius: '4px',
  //     primaryColour: '#ffffff', 
  //     secondaryColour: '#ffffff', 
  //     tertiaryColour: '#ffffff'
  // })
  ],
  declarations: [DocketListingComponent, AddDocketComponent, BillListingComponent, UpdateDocketStatusComponent, AddConsigneeComponent,AddConsignerComponent],
  entryComponents:[UpdateDocketStatusComponent,AddConsigneeComponent,AddConsignerComponent]
})
export class DocketModule { }
