import { Component, OnInit } from '@angular/core';
import { CommonService, User } from '../core/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userInfo: User;
  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.currentUser.subscribe((user: User) => {
     // if(user) {
        this.userInfo = user;
     // }
    })
  }
  logout(){
    this.commonService.removeAuth();
  }

}
