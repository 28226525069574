import { Component,Output,EventEmitter,OnInit, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {  MatDialog,MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConsignerModel } from '../../consigner-model';

@Component({
  
  selector: 'app-addconsiger',
  templateUrl: './addconsiger.component.html',
  styleUrls: ['./addconsiger.component.css'],
  
})
export class AddconsigerComponent implements OnInit {
  consignerForm: FormGroup;
  consignerModel : ConsignerModel=new ConsignerModel();
  submitted = false;
  name:string;
  constructor(private dialog: MatDialog,private fb:FormBuilder,public dialogRef:MatDialogRef<AddconsigerComponent>,  private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any)  { 

  }

  ngOnInit() {
  
  {
    this.dialogRef.close(this.consignerForm.value);
  }
  }
  

  initializeFormFields() {
    ;
  const configControls = {
    docketNumber:[this.consignerModel.consignerName],
    paymentTypeId: [this.consignerModel.description],
    consignerId: [this.consignerModel.gst]
  }
   this.consignerForm = this.formBuilder.group(configControls);
   console.log(this.consignerForm);

}



  onCancel(){
    ;
   this.dialogRef.close();
  }
 

  onSubmit() {
    
    if (!this.consignerForm.invalid) {
      this.submitted = true;
      this.consignerModel = this.consignerForm.value;
    
        
 
    }
  }
}
