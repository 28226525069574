import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonService, User } from '../core/common.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
 private registerUrl:string = environment.api_url +"/api/v1/users/register";
 private loginUrl:string = environment.api_url +"/api/v1/User/Login";
 private trackURL:string = environment.api_url +"/api/v1/User/Track";
 private downloadPODURL:string = environment.api_url +"/api/v1/User/DownloadPOD";

  constructor(private http:HttpClient, private commonService: CommonService) { }
  registerUser(data)
  {
    return  this.http.post(this.registerUrl,data);
      
  }
  loginUser(loginModel)
  {
    return  this.http.post(this.loginUrl,loginModel).pipe(
      map((res: User) => {
        this.commonService.setAuth(res);
        console.log(res);
        return res;
    }));
      
  }
  trackDocket(docketNo:string)
  {
    return  this.http.get(this.trackURL+"?docketNo="+docketNo).pipe(
      map((res: any) => {
        return res;
    }));
      
  }

   
  downloadFileBlob(fileName:string): Observable<Blob> {
    return this.http.post(this.downloadPODURL+"?fileName="+fileName, {},{responseType:'blob'});
  }
}
