import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
declare const Twilio: any;
@Component({
  selector: 'app-voicecall',
  templateUrl: './voicecall.component.html',
  styleUrls: ['./voicecall.component.css']
})
export class VoicecallComponent implements OnInit {
  phoneNumber: any;
  connection: any = null;
  device: any;
  callHideShow: any = true;
  stopHideShow: any = false;

  constructor(public http: HttpClient) { }

  ngOnInit(): void {

    Twilio.Device.incoming(function(conn) {
      debugger
      alert('call received');
  
      console.log('Incoming connection from ' + conn.parameters.From);
      const archEnemyPhoneNumber = '+15417280966';
    
      if (conn.parameters.From === archEnemyPhoneNumber) {
        conn.reject();
      } else {
        // accept the incoming connection and start two-way audio
        conn.cancel();
        //conn.accept();
      }
    });
    // debugger
    // this.http.get('https://coffee-pelican-9368.twil.io/capability-token',{}).subscribe((data:any)=>
    // {
    //   debugger
    //     console.log(data.token);
    //     this.device = Twilio.Device.setup(data.token);
    //     this.setupHandlers(this.device);
    //     console.log(this.device);
    // });
    this.http.get('https://e90b9aee7467.ngrok.io/token',{}).subscribe((data:any)=>
   {
     
       console.log(data);
       this.device = Twilio.Device.setup(data);
       this.setupHandlers(this.device);
       console.log(this.device);
   });
  // debugger
  //    let tkn=this.createToken();
  //      this.device = Twilio.Device.setup(tkn);
  //       this.setupHandlers(this.device);
  //       console.log(this.device);
  }

  setupHandlers(device) {
    
    device.on('ready', (_device) => {
      
        this.updateCallStatus("Ready to call");
    });

    device.on('error',  (error) => {
      
        this.updateCallStatus("ERROR: " + error.message);
    });

    // device.on("incoming", function(conn) {
    //   debugger
    //   var archEnemyPhoneNumber = "+917973037880";

    //   if (conn.parameters.From === archEnemyPhoneNumber) {
    //     conn.reject();
    //   } else {
    //     // accept the incoming connection and start two-way audio
    //     conn.accept();
    //   }
    // });

    device.on('connect', (connection) => {
      
        if ("phoneNumber" in connection.message) {
            this.updateCallStatus("In call with " + connection.message.phoneNumber);
            this.callHideShow = false;
            this.stopHideShow = true;
        } else {
            this.updateCallStatus("In call with support");
        }
    });

    device.on('disconnect',  (connection) => {
      
        this.updateCallStatus("Call End");
        this.stopHideShow = false;
        this.callHideShow = true;
    });
  }

  updateCallStatus(status: string): void {
   // console.log("Status -> ",  this.callStatusList)
  }

  callCustomer(){
    // if (!this.form.valid) {
    //   return;
    // }
    

    this.updateCallStatus("Calling " + this.phoneNumber + "...");

    var params = { "phoneNumber": this.phoneNumber,"id":1};//,"userId":1 
    // var params = {
    //   To: this.phoneNumber
    // };
    this.device.connect(params);
    //this.form.reset();
  }

  Stop(){
    this.device.disconnectAll();
    this.stopHideShow = false;
    this.callHideShow = true;
  }
}