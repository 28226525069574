import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource, MatPaginator, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { Router } from "@angular/router";
import { DocketService } from "src/app/main-portal/docket/docket.service";
import { DatePipe } from '@angular/common';
import { DocketListingModel } from '../docketListing-model';
// import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { formatDate } from '@angular/common';
import { ConsignerModel } from '../../masters/consigner-model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DocketModel } from '../docket-model';
import { UpdateDocketStatusComponent } from './update-docket-status/update-docket-status.component';
@Component({
  selector: 'app-docket-listing',
  templateUrl: './docket-listing.component.html',
  styleUrls: ['./docket-listing.component.css'],

})


export class DocketListingComponent implements OnInit {
  docketLength: number = 0;
  MyDataSource: MatTableDataSource<any>;
  docketListingModel: Array<DocketListingModel> = [];
  docketIdDate: any = [];
  consigner: any = [];
  fromDate: string = null;
  toDate: string = null;
  consignerId: number = null;
  checkedIDs: any = [];
  docketStatus: any[];
  displayedColumn = ['selectAll', 'docketNumber', 'docketDate', 'consignerName', 'consigneeName', 'fromCity', 'toCity','statusId', 'id'];
  @ViewChild('paginator') paginator: MatPaginator;
  constructor(
    private route: Router,
    private docketService: DocketService,
    private dialog: MatDialog

  ) {

  }

  ngOnInit() {
    this.getAllConsigners();
    this.paginator.pageIndex = 0;
    this.getDocketList();
    this.getDocketStatus();

  }
  onClick(val: number) {
    this.route.navigate(['main/docket/add-docket'], { queryParams: { id: val } });
  }
  // loadData()
  //   {
  //     this.showSpinner=true;
  //     setTimeout(()=>
  //     {this.showSpinner=false;
  //     },1000)
  //   }
  // showSpinner=false;
  getDocketList() {
 
    this.docketService.getDocketList((this.paginator.pageIndex + 1), (this.paginator.pageSize || 10), this.consignerId, this.fromDate, this.toDate)
      .subscribe((response: any) => {
      
        if (response != null) {
          this.docketListingModel = response;
          this.MyDataSource = new MatTableDataSource(this.docketListingModel);
          this.MyDataSource.data = response;
          this.docketLength = response[0].totalRecords;
        }
      });

  }
  getDocketStatus() {
    
    this.docketService.getDocketStatus()
      .subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.docketStatus = response.data || [];
        } else {
          this.docketStatus = [];
        }
      });
  }
  getPaginatorData(event: any) {
    this.getDocketList();
  }
  updateStatus(event:any,docket:DocketModel)
  {
    this.openDialog(docket.id,event.value);

  }
  downloadPdf(docketId: number) {
    this.docketService.getDocketPDF(docketId).subscribe((response: Blob) => {
      this.downLoadFile(response, "application/pdf", "Docket");
    });
  }
  downLoadFile(blob: Blob, filetype: string, filename: string) {
    var newBlob = new Blob([blob], { type: filetype });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob, filename);
      return;
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(data);
    }, 250);
  }

  onSelectAll(event: any) {
    this.docketListingModel.filter(a => a.isMovedForBill == 0).forEach(x => x.isCheckedBoolean = event);
  }
  onSelectChild(id: number, event: any) {
    this.docketListingModel.find(a => a.id == id).isCheckedBoolean = event;
  }

  onCLickCreateBill(docketIds: string, billDate: any = formatDate(new Date(), 'yyyy/MM/dd', 'en')) {
    this.docketService.createBill(docketIds, billDate)
      .subscribe((response: any) => {
        if (response != null) {
          this.getDocketList();
        }
      });
  }


  moveToBill() {
    if (this.consignerId > 0){
      this.checkedIDs = this.docketListingModel.filter(x => x.isCheckedBoolean == true && x.consignerId == this.consignerId).map(y => y.id);
      if (this.checkedIDs.length > 0) {
        this.onCLickCreateBill(this.checkedIDs.join(','))
      }
    // else if ((this.consignerId == this.checkedIDs.consignerId )) {
    //   alert('Please select selected consiner Name')
    // }
      else
        alert('Please select atleast one docket for selected consigner only')
    }
    else
      alert('Please select consigner from filters')


  }
  getAllConsigners() {
    this.docketService.getAllConsigners()
      .subscribe((response: any) => {
        this.consigner = response || [];
      });
  }
  onFilterSelect(value: any, type: string) {
    if (type == "consigner")
      this.consignerId = value;
    else if (type == "fromDate")
      this.fromDate = formatDate(value, 'yyyy-MM-dd', 'en');
    else if (type == "toDate")
      this.toDate = formatDate(value, 'yyyy-MM-dd', 'en');
  }

  clearAll()
  {
    this.consigner=null;
    this.fromDate=null;
    this.toDate=null;
  }
  openDialog(docketId:number,statusId:number): void {
    let docIds=docketId>0?[docketId]:this.docketListingModel.filter(x => x.isCheckedBoolean == true).map(y => y.id);
    if(docIds && docIds.length>0)
    {
    let dialogRef = this.dialog.open(UpdateDocketStatusComponent, {
   
      data: { docketIds:docIds
       }
    });
    
    dialogRef.afterClosed().subscribe(result => {    
      this.getDocketList();
    });
  }
}
 
}
