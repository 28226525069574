import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineChartComponent } from "src/app/shared/line-chart/line-chart.component";
import { PieChartComponent } from "src/app/shared/pie-chart/pie-chart.component";
import { ChartsModule } from "ng2-charts";

@NgModule({
  imports: [
    CommonModule,
    ChartsModule
  ],
  declarations: [    
    LineChartComponent,
    PieChartComponent
    ],
     exports: [
       ChartsModule,
       LineChartComponent,
       PieChartComponent
     ]
})
export class SharedModule { }
