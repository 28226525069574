

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DocketService } from '../../docket.service';
import { ConsigneeModel } from '../../consignee-model';



@Component({
  selector: 'app-add-consignee',
  templateUrl: './add-consignee.component.html',
  styleUrls: ['./add-consignee.component.css']
})
export class AddConsigneeComponent implements OnInit {
  consigneeForm: FormGroup;
  consigneeModel: ConsigneeModel;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<AddConsigneeComponent>,
    private docketService: DocketService, @Inject(MAT_DIALOG_DATA) public data: any) { 

        this.consigneeModel=new ConsigneeModel();
    }

  ngOnInit() {

    const configControls = {
      id: [this.consigneeModel.id],
      consigneeName: [this.consigneeModel.consigneeName],
      consigneeDescription: [this.consigneeModel.consigneeDescription],
      consigneeShortAddress: [this.consigneeModel.consigneeShortAddress],
      consigneeFullAddress: [this.consigneeModel.consigneeFullAddress],
      consigneeGST: [this.consigneeModel.consigneeGST],
      consigneeEmail: [this.consigneeModel.consigneeEmail],
      consigneePhone: [this.consigneeModel.consigneePhone]
    };
    this.consigneeForm = this.formBuilder.group(configControls);
  }
onSubmit()
{
    let formValues=this.consigneeForm.value;
    this.docketService.addConsignee(formValues).subscribe(result=>{
        alert('Consignee added');
        this.dialogRef.close();
    });

}
}
