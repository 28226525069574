import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { CommonService } from "src/app/core/common.service";
import { Observable } from "rxjs";
import { DocketModel } from './docket-model';
import { ConsigneeModel } from './consignee-model';
import { ConsignerModel } from './consigner-model';

// getstateByconsignerId

@Injectable({
  providedIn: 'root'
})
export class DocketService {
  getMasterPaymentTypesURL = 'api/v2/Master/GetMasterPaymentTypes';
  getMasterStatesURL = 'api/v2/Master/GetMasterStates';
  getConsignerULR = 'api/v2/Master/GetConsignerByPaymentTypeIds';
  getAllConsignersURL = 'api/v2/Consigner/GetConsigners';
  getConsignerStateRateURL = 'api/v2/Master/GetStateAndRateAsPerConsignerId';
  getMasterConsigneeURL = 'api/v2/Consignee/GetConsignees';
  createURL = 'api/v2/Docket/CreateDocket';
  getAllURL = 'api/v2/Docket/GetDockets';
  getAllPackingType = 'api/v2/Master/GetPackingType';
  getDocketStatusURL = 'api/v2/Master/GetDocketStatus';
  getAllDataURL = 'api/v2/Docket/GetDockets';

  getBillListURL='api/v2/Billing/GetBillList'
  getDocketId = 'api/v2/Docket/GetById';
  getConsignerState = 'api/v2/Master/GetStateAndRateAsPerConsignerId';
  getDocketItemValue = 'api/v2/Docket/GetDocketItems';
  getProductTypeURL='api/v2/Master/GetProductType';

  getConsignerAddressURL='api/v2/Consigner/GetConsignerAddress';
  getConsigneeAdddressURL='api/v2/Consignee/GetConsigneeAddress';
  getDocketPDFURL='api/v2/Docket/GetDocketPDF';
  getBillPDFURL='api/v2/Billing/GetBillPDF';
  getMasterRiskCoverageURL='api/v2/Master/GetRiskCoverageType';
  createBillURL='api/v2/Billing/CreateBill';
  updateDocketStatusURL='api/v2/Docket/UpdateDocketStatus';
  addConsigneeURL='api/v2/Consignee/CreateConsignee';
  generateDocketNumberURL='api/v2/Docket/GenerateAutoDocketNumber';
  addConsignerURL='api/v2/Consigner/CreateConsigner';
  constructor(
    private commonService: CommonService
  ) { }

  getMasterPaymentTypes() {
    let httpOptions = {
      headers: { 'Access-Control-Allow-Origin': '*' }
    };

    return this.commonService.getAll(this.getMasterPaymentTypesURL, {});
  }


  getMasterConsignee() {
    return this.commonService.getAll(this.getMasterConsigneeURL, {});
  }
  getMasterStates() {
    return this.commonService.getAll(this.getMasterStatesURL, {});
  }

  // getPackingType(packingTypeId){ 
  //   let url=this.getPackingType+ "?id=" +packingTypeId;
  //   return this.commonService.getAll(url, {});
  // }

  getPackingType() {
    return this.commonService.getAll(this.getAllPackingType, {});
  }
  getDocketStatus() {
    return this.commonService.getAll(this.getDocketStatusURL, {});
  }
  updateDocketStatus(docStatusObj:any)
  {
    let url = this.updateDocketStatusURL
    return this.commonService.post(url, docStatusObj);
  }

  getConsigner(paymentTypeId) {
    let url = this.getConsignerULR + "?id=" + paymentTypeId;
    return this.commonService.getAll(url, {});
  }
  getAllConsigners() {
    let url = this.getAllConsignersURL;
    return this.commonService.getAll(url, {});
  }

  getDocketPDF(docketId:number)
  {
    let url = this.getDocketPDFURL + "?docketId=" + docketId;
    return this.commonService.downloadFileBlob(url, {});
  }

  getBillPDF(billId:number)
  {
    let url = this.getBillPDFURL + "?billId=" + billId;
    return this.commonService.downloadFileBlob(url, {});
  }

  getProductTypeValue()
  {
    return this.commonService.getAll(this.getProductTypeURL,{});
  }


  getMasterRiskCoverage()
  {
    return this.commonService.getAll(this.getMasterRiskCoverageURL,{});
  }

  generateDocketNumber()
  {
    return this.commonService.getAll(this.generateDocketNumberURL,{});
  }


  getConsignerStateRate(consignerId) {
    let url = this.getConsignerULR + "?id=" + consignerId;
    return this.commonService.getAll(url, {});
  }

getConsignerAddress(consignerId:number)
{
  let url = this.getConsignerAddressURL + "?consignerId=" + consignerId;
    return this.commonService.getAll(url, {});
 }

getConsigneeAddress(consigneeId:number)
{
  let url = this.getConsigneeAdddressURL + "?consigneeId=" + consigneeId;
  return this.commonService.getAll(url, {});
}

  getConsignerStates(consignerId) {
    let url = this.getConsignerState + "?consignerId=" + consignerId;
    return this.commonService.getAll(url, {});
  }

  getDocketItemValuess(consignerId, invoiceValue, chargedWeight, baseRate,paymentTypeId,coddodType) {
    
    let url = this.getDocketItemValue + "?consignerId=" + consignerId + "&invoiceValue=" + invoiceValue + "&chargedWeight=" + chargedWeight + "&baseRate=" + baseRate  + "&paymentTypeId=" + paymentTypeId + "&coddodType=" + coddodType;
    return this.commonService.getAll(url, {});
  }
  createBill(docketIds:string,billDate:string) {
    
    let url = this.createBillURL + "?docketIds=" + docketIds + "&billDate=" + billDate ;
    return this.commonService.post(url, {});
  }


  getBaseRate(stateTypeId) {
    let url = this.getConsignerULR + "?id=" + stateTypeId;
    return this.commonService.getAll(url, {});
  }



  create(docketModel: DocketModel): Observable<DocketModel> {
    return this.commonService.post(this.createURL, docketModel);
  }

  getAll() {
    let url = this.getAllURL;
    return this.commonService.getAll(url, {});
  }

  getDocketList(pageNumber:number,pageSize:number,consignerId:number,fromDate:string,toDate:string) {
    let url = this.getAllDataURL;
    return this.commonService.getAll(url+"?pageNumber="+pageNumber+"&pageSize="+pageSize+"&consignerId="+consignerId+"&fromDate="+fromDate+"&toDate="+toDate, {});
  }

  getBillList(pageNumber:number,pageSize:number) {
    let url = this.getBillListURL;
    return this.commonService.getAll(url+"?pageNumber="+pageNumber+"&pageSize="+pageSize, {});
  }

  // getBillLists()
  // {
  //   return this.commonService.getAll(this.getBillListURL,{});
  // }


  getDocketById(docketId) {
    let url = this.getDocketId + "?id=" + docketId;
    return this.commonService.getById(url, {});
  }

  addConsignee(consignee: ConsigneeModel): Observable<ConsigneeModel> {
    return this.commonService.post(this.addConsigneeURL, consignee);
  }

  addConsigner(consigner: ConsignerModel): Observable<ConsigneeModel> {
    return this.commonService.post(this.addConsignerURL, consigner);
  }

}
