import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  activityListUrl:string = environment.api_url + "/activity";
  activityDetailsUrl:string = environment.api_url +"/activity/get-details";
  constructor(private http:HttpClient) { }

  getActivitiesList() {
    return this.http.get(this.activityListUrl);
  }
 getActivityDetails(id: string){
   let queryparams= '/'+id;
   return this.http.get(this.activityDetailsUrl+queryparams);
 }

}
