import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocketService } from "src/app/main-portal/docket/docket.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormArray, FormControl } from '@angular/forms';
import { DocketModel } from '../docket-model';
import { CommonService } from 'src/app/core/common.service';
import { MatDialog } from '@angular/material';
import { AddConsigneeComponent as ConsigneeComponent } from "../add-docket/add-consignee/add-consignee.component";
import { AddConsignerComponent as ConsignerComponent } from "../add-docket/add-consigner/add-consigner.component";


// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-add-docket',
  templateUrl: './add-docket.component.html',
  styleUrls: ['./add-docket.component.css']
})

export class AddDocketComponent implements OnInit {

  docketForm: FormGroup;
  submitted = false;
  paymentType: Array<any>;
  consigner: Array<any>;
  consignerAddress: any = [];
  consigneeShippingAddress: any = [];
  consigneeBillingAddress: any = [];
  product: any = [];
  riskType: any = [];
  baseRate: Array<any>;
  state: Array<any>;
  consignee: Array<any>;
  fromState: any = [];
  docketItem: any = [];
  toState: any = [];
  packingTypee: any[];

  consignerState: Array<any>;
  DocketItemModel: Array<any>;
  DocketId: Array<any>;
  docketModel: DocketModel = new DocketModel();
  showBaseRate = false;
  baseRateByStateForConsigner: number = 0;
  getItemDocket: any;
  showDocketRadio = false;
  selectedRadioField: string;
  radioOption: string[] = ['COD', 'DOD'];
  public show = false;
  notification = 0;
  showSpinner = false;
  roleName="Admin";




  keyPressAlphanumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private docketService: DocketService,
    private route: Router,
    private activatedRoute: ActivatedRoute, private commom: CommonService,
    private dialog: MatDialog


  ) {
    this.paymentType = [];
    this.consigner = [];
    this.baseRate = [];
    this.state = [];
    this.consignee = [];
    this.packingTypee = [];
    this.consignerState = [];

  }
  openDialog() {
    let dialogRef = this.dialog.open(ConsigneeComponent, {
      data: {
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      this.getMasterConsignee();
    });
  }

  openAddConsignerDialog() {
    let dialogRef = this.dialog.open(ConsignerComponent, {
      data: {
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      //this.getMasterConsigner();
    });
  }
  onChangeEventFunc(name: string, isChecked: boolean) {
    const cartoons = (this.docketForm.controls.name as FormArray);

    if (isChecked) {
      cartoons.push(new FormControl(name));
    } else {
      const index = cartoons.controls.findIndex(x => x.value === name);
      cartoons.removeAt(index);
    }
  }




  ngOnInit() {

    this.getProductType();
    this.getMasterPaymentTypes();
    this.getMasterConsignee();
    this.getMasterState();
    this.getPackingType();
    this.getMasterRiskCoverageType();

    this.activatedRoute.queryParams.subscribe(params => {
      const id = params['id'];
      if (id > 0)
        this.getDataBYId(id);
      else {
        this.docketService.generateDocketNumber().subscribe((response: any) => {
          this.docketModel.docketNumber=response.docketNumber;
          this.initializeFormFields();
        })

      }

    });
const user = JSON.parse(localStorage.getItem('user'));
if(user)

  {
     this.roleName=user.role.roleName;
  }
  }
 

  initializeFormFields() {

    const configControls = {

      id: [this.docketModel.id],
      docketNumber: [this.docketModel.docketNumber],
      paymentTypeId: [this.docketModel.paymentTypeId],
      consignerId: [this.docketModel.consignerId],
      consigneeId: [this.docketModel.consigneeId],
      actualWeight: [this.docketModel.actualWeight],
      quantity: [this.docketModel.quantity],
      chargedWeight: [this.docketModel.chargedWeight],
      totalInvoiceValue: [this.docketModel.totalInvoiceValue],
      invoiceNo: [this.docketModel.invoiceNo],
      invoiceDate: [this.docketModel.invoiceDate || new Date()],
      invoiceDescription: [this.docketModel.invoiceDescription],
      docketDate: [this.docketModel.docketDate || new Date()],
      freight: [this.docketModel.freight],
      fsc: [this.docketModel.fsc],
      coddod: [this.docketModel.coddod],
      fov: [this.docketModel.fov],
      hamali: [this.docketModel.hamali],
      topayCharges: [this.docketModel.toPayCharges],
      stCharges: [this.docketModel.stCharges],
      odaCharges: [this.docketModel.odaCharges],
      handlingCharges: [this.docketModel.handlingCharges],
      craneCharges: [this.docketModel.craneCharges],
      otherCharges: [this.docketModel.otherCharges],
      subTotal: [this.docketModel.subTotal],
      cgstugst: [this.docketModel.cgstugst],
      sgst: [this.docketModel.sgst],
      igst: [this.docketModel.igst],
      riskCoverageId: [this.docketModel.riskCoverageId],
      documentEnclosedId: [this.docketModel.documentEnclosedId],
      serviceMode: [this.docketModel.serviceMode],
      originStateId: [this.docketModel.originStateId],
      originCity: [this.docketModel.originCity],
      destinationState: [this.docketModel.destinationState],
      destinationCity: [this.docketModel.destinationCity],
      baseRate: [this.docketModel.baseRate],
      packingType: [this.docketModel.packingType],
      coddodType: [this.docketModel.coddodType],
      amount: [this.docketModel.amount],
      productTypeId: [this.docketModel.productTypeId],
      grandTotal: [this.docketModel.grandTotal],
      consignerAddressId: [this.docketModel.consignerAddressId],
      consignerPhone: [this.docketModel.consignerPhone],
      consignerEmail: [this.docketModel.consignerEmail],
      consignerGST: [this.docketModel.consignerGST],
      // consigneeShippingAddressId:[this.docketModel.consigneeShippingAddressId],
      consigneeBillingAddressId: [this.docketModel.consigneeBillingAddressId],
      consigneePhone: [this.docketModel.consigneePhone],
      consigneeEmail: [this.docketModel.consigneeEmail],
      consigneeGST: [this.docketModel.consigneeGST],
      ewayBillNo: [this.docketModel.ewayBillNo],
      packingDescription: [this.docketModel.packingDescription],
      remarks: [this.docketModel.remarks],
      consigneeShippingAddress: [this.docketModel.consigneeShippingAddress],
      riskCoverageTypeId: [this.docketModel.riskCoverageTypeId],
      dimension: [this.docketModel.dimension],
      orderNo: [this.docketModel.orderNo]
    }
    this.docketForm = this.formBuilder.group(configControls);
    console.log(this.docketForm);

  }

  get formControls() {
    return this.docketForm.controls;
  }
  toggle() {


    if (this.show)
      this.show = false;
    else
      this.show = true;

  }


  getMasterPaymentTypes() {
    this.docketService.getMasterPaymentTypes().subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.paymentType = response.data || [];
      } else {
        this.paymentType = [];
      }
    });
  }


  getMasterRiskCoverageType() {

    this.docketService.getMasterRiskCoverage().subscribe((response: any) => {
      this.riskType = response.data || [];
    })
  }

  getProductType() {

    this.docketService.getProductTypeValue().subscribe((response: any) => {
      this.product = response.data || [];
    });
  }
  onChangePaymentType(paymentTypeId: number) {
    this.docketService.getConsigner(paymentTypeId)
      .subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.consigner = response.data || [];
        } else {
          this.consigner = [];
        }
      });
  }






  onChangeTostate(stateId: number) {

    let consignerId = this.docketForm.value.consignerId;
    this.commonConsignerState(consignerId, stateId);
  }
  commonConsignerState(consignerId: number, stateId: number) {
    if (this.state.length > 0) {
      let baseRateObj = this.state.find(x => x.consignerId == consignerId && x.stateId == stateId);
      if (baseRateObj)
        this.docketForm.get('baseRate').patchValue(baseRateObj.baseRate);
    }
    else
      this.docketForm.get('baseRate').patchValue(0);

    this.showBaseRate = true;
  }

  onChangeClick(stateTypeId: number) {
    this.docketService.getConsigner(stateTypeId)
      .subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.baseRate = response.data || [];
        } else {
          this.baseRate = [];
        }
      });
  }
  getMasterConsignee() {

    this.docketService.getMasterConsignee().subscribe((response: any) => {
      if (response != null) {
        this.consignee = response || [];
      } else {
        this.consignee = [];
      }
    });
  }
  getMasterState() {
    this.docketService.getMasterStates().subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.fromState = response.data || [];
        this.toState = response.data || [];
      } else {
        this.fromState = [];
        this.toState = [];
      }
    });
  }
  getConsignerAddress(consignerId: number) {
    this.docketService.getConsignerAddress(consignerId).subscribe((response: any) => {
      this.consignerAddress = response;
    })
  }
  getConsigneeAddress(consigneeId: number) {
    this.docketService.getConsigneeAddress(consigneeId).subscribe((response: any) => {
      this.consigneeBillingAddress = response;
    })
  }





  getDocketItemValues(consignerId: number, invoiceValue: number, chargedWeight: number, baseRate: number, paymentTypeId: number, coddodType: number) {

    this.docketService.getDocketItemValuess(consignerId, invoiceValue, chargedWeight, baseRate, paymentTypeId, coddodType).subscribe((response: any) => {

      this.DocketItemModel = response || [];
      let dcAmount = 0, fscAmount = 0, topayAmount = 0, coddodAmount = 0, fovAmount = 0;
      if (this.DocketItemModel.length > 0) {
        let docketCharges = this.DocketItemModel.find(x => x.itemName == "Docket Charges");
        let fuelCharges = this.DocketItemModel.find(x => x.itemName == "Fuel Surcharge");
        let toPayCharges = this.DocketItemModel.find(x => x.itemName == "Topay Charges");
        let coddod = this.DocketItemModel.find(x => x.itemName == "COD/DOD");
        let freightOnValue = this.DocketItemModel.find(x => x.itemName == "Freight On Value");

        if (docketCharges != null)
          dcAmount = docketCharges.docketItemValue;
        if (fuelCharges != null)
          fscAmount = fuelCharges.docketItemValue;
        if (toPayCharges != null)
          topayAmount = toPayCharges.docketItemValue;
        if (coddod != null)
          coddodAmount = coddod.docketItemValue;
        if (freightOnValue != null)
          fovAmount = freightOnValue.docketItemValue;
      }
      this.docketForm.get('fsc').patchValue(fscAmount);
      this.docketForm.get('fov').patchValue(fovAmount);
      this.docketForm.get('topayCharges').patchValue(topayAmount);
      this.docketForm.get('coddod').patchValue(coddodAmount);
      this.docketForm.get('stCharges').patchValue(dcAmount);
      this.getSubTotal();
    });
  }




  getPackingType() {
    this.docketService.getPackingType()
      .subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.packingTypee = response.data || [];
        } else {
          this.packingTypee = [];
        }
      });
  }



  onChangeConsigner(consignerId: number, eventType: string = 'auto') {
    this.getConsignerAddress(consignerId);
    this.docketForm.get('baseRate').patchValue(0);
    this.docketService.getConsignerStates(consignerId)
      .subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.state = response.data || [];
          if (this.docketModel.consignerId > 0)
            this.onChangeTostate(this.docketModel.destinationState);
        } else {
          this.state = [];
        }
      });
    if (eventType != 'auto')
      this.setConsignerInfo(consignerId);
  }
  setConsignerInfo(consignerId: number) {
    let consignerInfo = this.consigner.find(x => x.id == consignerId);
    if (consignerInfo != null) {
      let consignerPhone = consignerInfo.consignerPhone;
      let consignerGST = consignerInfo.consignerGST;
      let consignerEmail = consignerInfo.consignerEmail;
      this.docketForm.get('consignerPhone').patchValue(consignerPhone);
      this.docketForm.get('consignerGST').patchValue(consignerGST);
      this.docketForm.get('consignerEmail').patchValue(consignerEmail);
    }
    else {
      this.docketForm.get('consignerPhone').patchValue(null);
      this.docketForm.get('consignerGST').patchValue(null);
      this.docketForm.get('consignerEmail').patchValue(null);
    }
  }

  onChangeConsignee(consigneeId: number, eventType: string = 'auto') {
    this.getConsigneeAddress(consigneeId);
    if (eventType != 'auto')
      this.setConsigneeInfo(consigneeId);

  }
  setConsigneeInfo(consigneeId: number) {
    let consigneeInfo = this.consignee.find(x => x.id == consigneeId);
    if (consigneeInfo != null) {
      let consigneePhone = consigneeInfo.consigneePhone;
      let consigneeGST = consigneeInfo.consigneeGST;
      let consigneeEmail = consigneeInfo.consigneeEmail;
      this.docketForm.get('consigneePhone').patchValue(consigneePhone);
      this.docketForm.get('consigneeGST').patchValue(consigneeGST);
      this.docketForm.get('consigneeEmail').patchValue(consigneeEmail);
    }
    else {
      this.docketForm.get('consigneePhone').patchValue(null);
      this.docketForm.get('consigneeGST').patchValue(null);
      this.docketForm.get('consigneeEmail').patchValue(null);
    }
  }
  checked: boolean = true;

  getDataBYId(val: any) {
    this.docketService.getDocketById(val).subscribe((response: any) => {
      this.docketModel = response;
      this.initializeFormFields();
      this.onChangePaymentType(response.paymentTypeId);
      this.onChangeConsigner(this.docketModel.consignerId);
      this.onChangeConsignee(this.docketModel.consigneeId);
    });

  }

  // downloadCSVFile(){
  //   this.downloadPdf(1).subscribe(
  //       (res) => {      
  //           saveAs(res,'test.pdf')
  //       }
  //   );
  //     }






  onBlurForItemValues() {

    this.docketModel = this.docketForm.value;
    let baseRate = this.docketForm.value.baseRate;
    let chargedWeight = this.docketForm.value.chargedWeight;
    let invoiceValue = this.docketForm.value.totalInvoiceValue;
    let consignerId = this.docketForm.value.consignerId;
    let paymentTypeId = this.docketForm.value.paymentTypeId;
    let coddodType = this.docketForm.value.coddodType;
    let freight = 0;
    if (baseRate && chargedWeight) {
      freight = baseRate * chargedWeight;
      this.docketForm.get('freight').patchValue(freight);
    }
    this.getDocketItemValues(consignerId, invoiceValue, chargedWeight, baseRate, paymentTypeId, coddodType);

  }



  //   pdfMaker()
  //   {





  //     const doc=new jspdf.jsPDF("p", "mm", "a4");


  // doc.setFontSize(15);

  //     doc.text('New Vedanta Copy ',6,5);

  //     doc.setFontSize(8);

  //     doc.text('New Vedanta Enterprises: Gugal ',6,10);
  //     doc.text('PAN : 123456',6,15);
  //     doc.text('GSTIN NO : 123456 ',6,20);
  //     doc.text('Category : Sunrise',6,25);
  //     doc.text('Consigner : Sunrise',6,30);
  //     doc.text('City : Ghumarwin',6,35);
  //     doc.text('Phone NO : 8219521688',6,40);
  //     doc.text('Type of Packing : Carton Box',6,45);
  //     doc.text('No of Packages : 100',6,50);
  //     doc.text('Description of Goods : hello',6,55);
  //     doc.text('Delivery Address : Bilaspur',6,60);
  //     doc.text('Docket Date : 15 April,2020',6,65);
  //     doc.text('Origin : Bilaspur',6,70);
  //     doc.text('Destination : Bilaspur',6,75);
  //     doc.text('Actual Weight: 100',6,80);
  //     doc.text('Charged Weight : 100',6,85);
  //     doc.text('Invoice No : 100',6,90);
  //     doc.text('Invoice Date: 15 April,2020',6,95);



  //     doc.text('Billing Station : Chandigarh',120,10);
  //     doc.text('CODDOD Amount : 100',120,15);
  //     doc.text('Freight: 100',120,20);
  //     doc.text('FSC : 100',120,25);
  //     doc.text('FOV: 100',120,30);
  //     doc.text('Hamali : 100',120,35);
  //     doc.text('Topay Charges: 100',120,40);
  //     doc.text('ST Charges : 100',120,45);
  //     doc.text('ODA Charges: 100',120,50);
  //     doc.text('Handling Charges : 100',120,55);
  //     doc.text('Crane Charges: 100',120,60);
  //     doc.text('Other Charges : 100',120,65);
  //     doc.text('Sub Total: 500',120,70);
  //     doc.text('CGST/UGST : 100',120,75);
  //     doc.text('SGST: 100',120,80);
  //     doc.text('IGST : 100',120,85);
  //     doc.text('Charged Weight : 100',120,90);

  // doc.setFontSize(15);

  // doc.text('Customer Copy ',6,105);
  // doc.setFontSize(8);
  // doc.text('New Vedanta Enterprises: Gugal ',6,110);
  // doc.text('PAN : 123456',6,115);
  // doc.text('GSTIN NO : 123456 ',6,120);
  // doc.text('Category : Sunrise',6,125);
  // doc.text('Consigner : Sunrise',6,130);
  // doc.text('City : Ghumarwin',6,135);
  // doc.text('Phone NO : 8219521688',6,140);
  // doc.text('Type of Packing : Carton Box',6,145);
  // doc.text('No of Packages : 100',6,150);
  // doc.text('Description of Goods : hello',6,155);
  // doc.text('Delivery Address : Bilaspur',6,160);
  // doc.text('Docket Date : 15 April,2020',6,165);
  // doc.text('Origin : Bilaspur',6,170);
  // doc.text('Destination : Bilaspur',6,175);
  // doc.text('Actual Weight: 100',6,180);
  // doc.text('Charged Weight : 100',6,185);
  // doc.text('Invoice No : 100',6,190);
  // doc.text('Invoice Date: 15 April,2020',6,195);



  // doc.text('Billing Station : Chandigarh',120,110);
  // doc.text('CODDOD Amount : 100',120,115);
  // doc.text('Freight: 100',120,120);
  // doc.text('FSC : 100',120,125);
  // doc.text('FOV: 100',120,130);
  // doc.text('Hamali : 100',120,135);
  // doc.text('Topay Charges: 100',120,140);
  // doc.text('ST Charges : 100',120,145);
  // doc.text('ODA Charges: 100',120,150);
  // doc.text('Handling Charges : 100',120,155);
  // doc.text('Crane Charges: 100',120,160);
  // doc.text('Other Charges : 100',120,165);
  // doc.text('Sub Total: 500',120,170);
  // doc.text('CGST/UGST : 100',120,175);
  // doc.text('SGST: 100',120,180);
  // doc.text('IGST : 100',120,185);
  // doc.text('Charged Weight : 100',120,190);
  // // doc.setFontSize(15);
  // // doc.text('Docket Number: 1001  ',6,115);
  // // doc.setFontSize(15);
  // // doc.text('Consigner: ',6,125);
  // // doc.setFontSize(20);


  // // doc.text('Other Information ',5,200);
  // // doc.setFontSize(15);
  // // doc.text('Docket Number: 1001  ',6,210);
  // // doc.setFontSize(15);
  // // doc.text('Consigner: ',6,220);

  // doc.setFontSize(15);

  // doc.text('Shipping Copy ',6,205);

  // doc.setFontSize(8);

  // doc.text('New Vedanta Enterprises: Gugal ',6,210);
  // doc.text('PAN : 123456',6,215);
  // doc.text('GSTIN NO : 123456 ',6,220);
  // doc.text('Category : Sunrise',6,225);
  // doc.text('Consigner : Sunrise',6,230);
  // doc.text('City : Ghumarwin',6,235);
  // doc.text('Phone NO : 8219521688',6,240);
  // doc.text('Type of Packing : Carton Box',6,245);
  // doc.text('No of Packages : 100',6,250);
  // doc.text('Description of Goods : hello',6,255);
  // doc.text('Delivery Address : Bilaspur',6,260);
  // doc.text('Docket Date : 15 April,2020',6,265);
  // doc.text('Origin : Bilaspur',6,270);
  // doc.text('Destination : Bilaspur',6,275);
  // doc.text('Actual Weight: 100',6,280);
  // doc.text('Charged Weight : 100',6,285);
  // doc.text('Invoice No : 100',6,290);
  // doc.text('Invoice Date: 15 April,2020',6,295);



  // doc.text('Billing Station : Chandigarh',120,210);
  // doc.text('CODDOD Amount : 100',120,215);
  // doc.text('Freight: 100',120,220);
  // doc.text('FSC : 100',120,225);
  // doc.text('FOV: 100',120,230);
  // doc.text('Hamali : 100',120,235);
  // doc.text('Topay Charges: 100',120,240);
  // doc.text('ST Charges : 100',120,245);
  // doc.text('ODA Charges: 100',120,250);
  // doc.text('Handling Charges : 100',120,255);
  // doc.text('Crane Charges: 100',120,260);
  // doc.text('Other Charges : 100',120,265);
  // doc.text('Sub Total: 500',120,270);
  // doc.text('CGST/UGST : 100',120,275);
  // doc.text('SGST: 100',120,280);
  // doc.text('IGST : 100',120,285);
  // doc.text('Charged Weight : 100',120,290);

  //   doc.output('dataurlnewwindow')
  //   doc.save('ADDDocket.pdf');
  // }


  // loadData()
  // {
  //   this.showSpinner=true;
  //   setTimeout(()=>
  //   {this.showSpinner=false;
  //   },3600)
  // }
  onSubmit() {
    if (!this.docketForm.invalid) {
      this.docketModel = this.docketForm.value;
      this.showSpinner = true;
      this.docketService.create(this.docketModel).subscribe((response: any) => {
        this.showSpinner = false;

        alert(response);
        this.route.navigate(['main/docket/docket-listing']);
      });
    }
  }
  getSubTotal() {
    let formVals = this.docketForm.value;
    let subTotal = (formVals.freight || 0) + (formVals.fov || 0) + (formVals.fsc || 0)
      + (formVals.coddod || 0) + (formVals.hamali || 0) + (formVals.topayCharges || 0) + (formVals.stCharges || 0) + (formVals.odaCharges || 0)
      + (formVals.handlingCharges || 0) + (formVals.craneCharges || 0) + (formVals.otherCharges || 0);

    this.docketForm.get('subTotal').patchValue(subTotal);
    let formVal = this.docketForm.value;

    let grandTotal = (formVal.subTotal || 0) + (formVal.igst || 0) + (formVal.sgst || 0) + (formVal.cgstugst || 0);
    this.docketForm.get('grandTotal').patchValue(grandTotal);
  }

  onConsigneeAddressChange(addressId: number) {
    let shipAddress = this.consigneeBillingAddress.find(x => x.id).address;
    this.docketForm.get('consigneeShippingAddress').patchValue(shipAddress);

  }

}




function AddConsigneeComponent(AddConsigneeComponent: any, arg1: { data: {}; }) {
  throw new Error('Function not implemented.');
}

