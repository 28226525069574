export class ConsignerModel {
    id:                 number=0;
    consignerName: string;
    consignerDescription: string;
    consignerShortAddress:string;
    consignerFullAddress:string;
    consignerGST:string;
    consignerEmail:string;
    consignerPhone:string;
    paymentTypeId :number =null

}
