import { Component, OnInit, Input } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DocketListingComponent } from 'src/app/main-portal/docket/docket-listing/docket-listing.component';
import { DocketModel } from 'src/app/main-portal/docket/docket-model';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {
  // @Input() lineChartData: any[];
  // @Input() lineChartLabels: any[];

  // public lineChartType: string = 'line';
  // constructor() {
  //   this.lineChartData= [];
  //   this.lineChartLabels= [];
  //  }
  ngOnInit() {

  }

    lineChartData: ChartDataSets[] = [
    { data: [10, 20, 25, 10, 40, 21,34], label: 'Docket Count' },
  ];

  lineChartLabels: Label[] = ['22-07-2021', '20-07-2021', '25-07-2021', '28-07-2021', '29-07-2021', '22-07-2021','22-07-2021'];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
}
