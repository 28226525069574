import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialog,MatDialogConfig, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { AddconsigerComponent } from './addconsiger/addconsiger.component';
@Component({
  selector: 'app-manage-consigner',
  templateUrl: './manage-consigner.component.html',
  styleUrls: ['./manage-consigner.component.css']
})
export class ManageConsignerComponent implements OnInit {
 
  consignerName: string;
  description:string;
  constructor(private dialog: MatDialog)
  
 {}
    
  ngOnInit() {
  }
  // onNoClick(): void {
  //   const dialogConfig=new MatDialogConfig();
  //   dialogConfig.disableClose=true;
  //   this.dialog.open(AddconsigerComponent,dialogConfig)
    
  // }
  openDialog(): void {
    ;
    let dialogRef = this.dialog.open(AddconsigerComponent, {
   
      data: { consignerName: this.consignerName,description:this.description
       }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      ;
      this.consignerName = result;

      console.log("Dialog output:", this.consignerName);
  
      // return dialogRef.afterClosed();
      
     
    });
  }
 
  
}

