import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { ContainerComponent } from './container/container.component';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { DocketListingComponent } from "src/app/main-portal/docket/docket-listing/docket-listing.component";
import { NoAuthGuard } from "src/app/auth/noAuth.guard";
import { NgModule } from "@angular/core";
import { TrackShipmentComponent } from './track-shipment/track-shipment.component';
import { WebsiteContainerComponent } from './website-container/website-container.component';


const appRoutes: Routes = [
    {
        path: 'main',
        canActivate: [AuthGuard], 
        component: ContainerComponent,
        children: [
          {
            path: '',
            loadChildren: './main-portal/main-portal.module#MainPortalModule'
          }
        ]
      },
      {
        path: '',
       // canActivate: [NoAuthGuard], 
        component: WebsiteContainerComponent,
        children: [
          {
            path: '',
            loadChildren: './website/website.module#WebsiteModule'
          }
        ]
      },

  //     {
  //   path: '',
  //   canActivate: [NoAuthGuard], 
  //   component: LoginComponent
  // },

    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'track', component: TrackShipmentComponent },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

 export const routing = RouterModule.forRoot(appRoutes)
