import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-track-shipment',
  templateUrl: './track-shipment.component.html',
  styleUrls: ['./track-shipment.component.css']
})
export class TrackShipmentComponent implements OnInit {
  submitted = false;
  isData=false;
  MyDataSource: MatTableDataSource<any>;  
   searchText:any='';
   docketDetail:any;
   displayedColumn = ['servingLocation','activityDate','activityStatus','podFile'];
  constructor(private fb: FormBuilder,
    private authService:AuthService, ) { }

  ngOnInit() {
    //this.MyDataSource = new MatTableDataSource();  
   // this.MyDataSource.data = [{id:1,userid:'2',title:'a',completed:'yes'}];  
  }
  onSubmit() {
    this.authService.trackDocket(this.searchText).subscribe((res: any)=>{
      if(res) {
        this.docketDetail=res.trackDocketModel
          this.isData=true;
          this.MyDataSource = new MatTableDataSource(res.trackDocketModelHistories);
          this.MyDataSource.data = res.trackDocketModelHistories;
      }
    });
  }

  downloadPOD(fileName:string)
  {
    this.authService.downloadFileBlob(fileName).subscribe((response: Blob) => {
      this.downLoadFile(response, "application/pdf", fileName);
    });
  }

  downLoadFile(blob: Blob, filetype: string, filename: string) {
    var newBlob = new Blob([blob], { type: filetype });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob, filename);
      return;
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(data);
    }, 250);
  }
}
