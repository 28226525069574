import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { Router } from "@angular/router";
import { DocketService } from "src/app/main-portal/docket/docket.service";
@Component({
  selector: 'app-bill-listing',
  templateUrl: './bill-listing.component.html',
  styleUrls: ['./bill-listing.component.css']
})
export class BillListingComponent implements OnInit {
  billLength: number = 0;
  MyDataSource: MatTableDataSource<any>;
  displayedColumn = ['billNo', 'billDate', 'docketsInfo', 'consignerName','id'];
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
private docketService: DocketService,) { }

  ngOnInit() {
    this.paginator.pageIndex = 0;
    this.getBillListData();
  }
  getBillListData() {
    
    this.docketService.getBillList((this.paginator.pageIndex + 1), (this.paginator.pageSize || 10))
      .subscribe((response: any) => {
        if (response != null) {
          this.MyDataSource = new MatTableDataSource(response);
          this.MyDataSource.data = response;
          this.billLength = response[0].totalRecords;
        }
      });

  }
  getPaginatorData(event: any) {
    this.getBillListData();
  }

  downloadPdf(billId:number) {
    
     this.docketService.getBillPDF(billId).subscribe((response:Blob)=>
     {
       
        this.downLoadFile(response,"application/pdf","Bill");
     });
 }
 downLoadFile(blob: Blob, filetype: string, filename: string) {
   
   var newBlob = new Blob([blob], { type: filetype });
   
   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
     window.navigator.msSaveOrOpenBlob(newBlob, filename);
     return;
   }
   
   const data = window.URL.createObjectURL(newBlob);
   var link = document.createElement('a');
   document.body.appendChild(link);
   link.href = data;
   link.download = filename;
   link.click();
   setTimeout(function () {
    
     document.body.removeChild(link);
     window.URL.revokeObjectURL(data);
   }, 100);
 }

}
