import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
//import swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private authService:AuthService, 
    private router: Router  ) {
   // get return url from route parameters or default to '/'
   this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loginForm = this.fb.group({
      username: ['', [Validators.required,]],
      password: ['', [Validators.required,]]
    });
   }
  get formControls() { return this.loginForm.controls }
  ngOnInit() {
  }
  onSubmit() {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = true;
    // this.fb.registerUser();
    let data = this.loginForm.value;
    this.authService.loginUser(data).subscribe((res: any)=>{
      if(res) {
       // alert('success');
         console.log(res);
      
        this.router.navigate(['/main/dashboard']);
      }
  
    },(err)=> {
     
      this.submitted = false;
     
      // alert("UserName and Password is inCorrect");
      alert(err.error.message);
    });
  }

}

