import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import {
  MatSidenavModule,
  MatListModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatTableModule,
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MainComponent } from "./main/main.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { RegisterComponent } from "./register/register.component";
import { ContainerComponent } from "./container/container.component";
import { routing } from "./app.routing";
import { ActivityService } from "./main/activity.service";
import { HttpClientModule } from "@angular/common/http";
import { LoginComponent } from "./login/login.component";
import { AuthService } from "./auth/auth.service";
import { FooterComponent } from "./footer/footer.component";
import { CommonService } from "./core/common.service";
import { SideNavComponent } from "./side-nav/side-nav.component";
import { MainPortalModule } from "./main-portal/main-portal.module";
import { SharedModule } from "src/app/shared/shared.module";
import { DocketModule } from "src/app/main-portal/docket/docket.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpTokenInterceptor } from "src/app/core/interceptor/http.token.interceptor";
import { MatPaginatorModule } from "@angular/material";
import { MatRadioModule } from "@angular/material";
import { MastersModule } from "src/app/main-portal/masters/masters.module";
import { MatDialogModule } from "@angular/material";
import { DatePipe } from "@angular/common";
import { TooltipModule } from "ng2-tooltip-directive";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TrackShipmentComponent } from "./track-shipment/track-shipment.component";
import { WebsiteContainerComponent } from "./website-container/website-container.component";
import { WebsiteModule } from "./website/website.module";
import { WebsiteHeaderComponent } from "./website-header/website-header.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { WebsiteFooterComponent } from './website-footer/website-footer.component';
//imports: [ MatDialogModule ];
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    GalleryComponent,
    RegisterComponent,
    ContainerComponent,
    LoginComponent,
    FooterComponent,
    SideNavComponent,
    TrackShipmentComponent,
    WebsiteContainerComponent,
    WebsiteHeaderComponent,
    WebsiteFooterComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    routing,
    HttpClientModule,
    NoopAnimationsModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DocketModule,
    MatPaginatorModule,
    MatRadioModule,
    MastersModule,
    MatDialogModule,
    TooltipModule,
    MatProgressSpinnerModule,
    NgbModule,
    MatTableModule, //,
    //WebsiteModule
  ],
  providers: [
    CommonService,
    ActivityService,
    AuthService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
