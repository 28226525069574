import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConsigneeModel } from '../../consignee-model';
import { DocketService } from '../../docket.service';
import { ConsignerModel } from '../../consigner-model';



@Component({
  selector: 'app-add-consigner',
  templateUrl: './add-consigner.component.html',
  styleUrls: ['./add-consigner.component.css']
})
export class AddConsignerComponent implements OnInit {
  consignerForm: FormGroup;
  consignerModel: ConsignerModel;
  paymentType: Array<any>;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<AddConsignerComponent>,
    private docketService: DocketService, @Inject(MAT_DIALOG_DATA) public data: any) { 

        this.consignerModel=new ConsignerModel();
        this.paymentType = [];
    }

  ngOnInit() {
    this.getMasterPaymentTypes();
    const configControls = {
      id: [this.consignerModel.id],
      consignerName: [this.consignerModel.consignerName],
      consignerDescription: [this.consignerModel.consignerDescription],
      consignerShortAddress: [this.consignerModel.consignerShortAddress],
      consignerFullAddress: [this.consignerModel.consignerFullAddress],
      consignerGST: [this.consignerModel.consignerGST],
      consignerEmail: [this.consignerModel.consignerEmail],
      consignerPhone: [this.consignerModel.consignerPhone],
      paymentTypeId: [this.consignerModel.paymentTypeId],
    };
    this.consignerForm = this.formBuilder.group(configControls);
  }
onSubmit()
{
    let formValues=this.consignerForm.value;
    this.docketService.addConsigner(formValues).subscribe(result=>{
        alert('Consigner added');
        this.dialogRef.close();
    });

}
getMasterPaymentTypes() {
  this.docketService.getMasterPaymentTypes().subscribe((response: any) => {
    if (response.statusCode == 200) {
      this.paymentType = response.data || [];
    } else {
      this.paymentType = [];
    }
  });
}
}
