import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  // emailFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.email,
  // ]);
  registerForm: FormGroup;
  submitted = false;
  matcher = new MyErrorStateMatcher();
  constructor(private fb: FormBuilder,private authService:AuthService  ) {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email,]],
      username: ['', [Validators.required,]],
      firstName: ['', [Validators.required,]],
      lastName: ['', [Validators.required,]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    })
   }

   get formControls() { return this.registerForm.controls }

  ngOnInit() {
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.submitted = true;
    // this.fb.registerUser();
    let data = this.registerForm.value;
    this.authService.registerUser(data).subscribe(res=>{
      if(res) {
        alert('success');
      }
    },(err)=> {console.log(err)});
  }


    
}
/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
