export class DocketModel {
    id:                 number=0;
    docketNumber:       string=null;
    docketDate:         string=null;
    paymentTypeId:      number=null;
    consignerId:        number=null;
    consignerName:      string=null;
    originStateId:      number=null;
    consigneeId:        number=null;
    consigneeName:      string=null;
    actualWeight:       number=null;
    quantity:           number=null;
    chargedWeight:      number=null;
    totalInvoiceValue:  number=null;
    invoiceNo:          string=null;
    invoiceDate:        string=null;
    freight:            number=0;
    fsc:                number=0;
    coddod:             number=0;
    fov:                number=0;
    hamali:             number=0;
    toPayCharges:       number=0;
    stCharges:          number=0;
    odaCharges:         number=0;
    handlingCharges:    number=0;
    craneCharges:       number=0;
    otherCharges:       number=0;
    subTotal:           number=0;
    cgstugst:           number=0;
    sgst:               number=0;
    igst:               number=0;
    riskCoverageId:     number=0;
    documentEnclosedId: number=0;
    serviceMode:        number=0;
    date:               Date=null;
    originCity:         string=null;
    destinationState:   number=null;
    destinationCity:    string=null;
    baseRate:           number=null;
    coddodType:         string=null;
    amount:             number=0;
    invoiceDescription: string=null;
    packingType:        number=null;
    productTypeId:        number=null;
    grandTotal  :       number=0;
    consignerAddressId: number=null;
    consignerPhone:     string=null;
    consignerEmail:     string=null;
    consignerGST:       string=null;
    consigneeShippingAddressId:number=null;
    consigneeBillingAddressId: number=null;  
    consigneePhone :     string=null;
    consigneeEmail:      string=null;
    consigneeGST :      string=null;
    ewayBillNo:         string=null;
    packingDescription: string=null;
    remarks: string=null;
    consigneeShippingAddress:string=null;
    riskCoverageTypeId:number=null;
    dimension:string=null;
    orderNo:string=null;

}
